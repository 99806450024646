import * as React from "react";
import { HtmlHead, Link } from "@cdv/jazz-web/components";
import { Typography, Box, Container } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";

export const Head = () => (
  <HtmlHead title="Projects | Centrum dopravního výzkumu, v. v. i.">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="Projects" lang="cs" />
    <meta name="keywords" content="projects" lang="cs" />
    <meta name="robots" content="index, follow" />
    <meta name="rating" content="general" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </HtmlHead>
);

export default function Page() {
  const aCss = {
    a: {
      textDecoration: "none",
      color: "#e05206",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
    },
    "a:hover": {
      borderBottom: "1px solid #e05206",
      transitionDuration: "0.3s",
    },
  };

  return (
    <Layout>
      <Container sx={aCss}>
        <ul>
          <li>Treefall forecast for securing traffic safety on railways (2021–2023)</li>
          <li>
            Research on measures to eliminate the number of wildlife-vehicle collisions on secondary
            roads (2021–2023)
          </li>
          <li>
            Environmental Education through Roadkills Observation Systems (Enveros, 2018-2020)
          </li>
          <li>
            Avison (2017 – 2019)
            <br />
            Study of spatiotemporal characteristics of traffic crashes under low visibility on Czech
            Roads
          </li>
          <li>
            Blackspots (2016 – 2017)
            <br />
            Animal-vehicle collisions at crossing between green and grey infrastructure
          </li>
          <li>Girosaf (2011 – 2014)</li>
          <li>
            <Link to="http://trisk.cdvinfo.cz/en">Trisk </Link>(2010 – 2015)
          </li>
        </ul>
      </Container>
    </Layout>
  );
}
